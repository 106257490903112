import './PrimaryButton.css';

export const PrimaryButton = ({style,onClick, text}) => {
    return (<button
    className="primary-button"
    style={style}
        onClick={onClick}
    >
    {text}
    </button>)
}