import { PhoneInput } from '../components/PhoneInput';
import { useState } from 'react';
import { ResultModal } from './ResultModal';
import { PrimaryButton } from '../components/PrimaryButton';



export const ValidatePage = () => {

    const [phone, setPhone] = useState('');
    const [validPhone, setValidPhone] = useState(true);
    const [open, setOpen] = useState(false);


    const handleChange = (newPhone) => setPhone(newPhone);

    const handleOnClick = () => {
            setOpen(true);
    }

    return (
        <div className='page-contatiner'>
            <PhoneInput phone={phone} handleChange={handleChange}></PhoneInput>
            <PrimaryButton onClick={handleOnClick} text={"Check Now!"}/>
            <ResultModal open={open} setOpen={setOpen} phone={phone}/>
        </div>
    )
}