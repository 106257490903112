import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, Box } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import { Table, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ClientService from '../service/ClientService';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
export const  ResultModal = ({open, setOpen, phone}) => {
  const clientService = new ClientService();
  const [phoneResult, setPhoneResult] = React.useState({
    "carrier": "-",
    "formattedNumber": "-",
    "internalNumber": "-",
    "localNumber": "-",
    "localTime": "-",
    "location": "-",
    "timezone": "-",
    "type": "-",
    "valid": false
});
  React.useEffect(() => {
    phone = phone.replaceAll(' ','');
    clientService.getPhoneOutputResponse(phone).then(response => {
      setPhoneResult(response);
    }).catch(err => console.log(err));
  }, [open])
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={style}>
        {phoneResult.valid? <> <TaskAltIcon className='modal-icon-valid'/>
        <div className='modal-title' style={{color:'green'}}>{phone}</div> </>
        : <><CancelOutlinedIcon className='modal-icon-invalid'/>
        <div className='modal-title' style={{color:'red'}}>{phone}</div></>}

{phoneResult.valid?
        <TableContainer>
                <Table>
                    <TableRow><TableField>Status</TableField>
                        <TableValue>Valid</TableValue>
                    </TableRow>
                    <TableRow><TableField>Line Type</TableField>
                        <TableValue>{phoneResult.type}</TableValue>
                    </TableRow>
                    <TableRow><TableField>Carrier</TableField>
                        <TableValue>{phoneResult.carrier}</TableValue>
                    </TableRow>
                    <TableRow><TableField>Location</TableField>
                        <TableValue>{phoneResult.location}</TableValue>
                    </TableRow>
                    {/* <TableRow><TableField>Country Name</TableField>
                        <TableValue>{phone.location}</TableValue>
                    </TableRow> */}
                    <TableRow><TableField>Country Timezone</TableField>
                        <TableValue>{phoneResult.timezone}</TableValue>
                    </TableRow>
                    {/* <TableRow><TableField>Country Code</TableField>
                        <TableValue>IN</TableValue>
                    </TableRow> */}
                    <TableRow><TableField>International Format</TableField>
                        <TableValue>{phoneResult.internalNumber}</TableValue>
                    </TableRow>
                    <TableRow><TableField>Local Format</TableField>
                        <TableValue>{phoneResult.localNumber}</TableValue>
                    </TableRow>
                    <TableRow><TableField>E164 Fornat</TableField>
                        <TableValue>{phoneResult.formattedNumber}</TableValue>
                    </TableRow>
                    {/* <TableRow><TableField>Can be Internationally Dialed</TableField>
                        <TableValue>Yes</TableValue>
                    </TableRow> */}
                    <TableRow><TableField>Validated On</TableField>
                        <TableValue>{phoneResult.localTime}</TableValue>
                    </TableRow>
                </Table>
            </TableContainer>:
            <TableContainer >
            <Table>
                <TableRow><TableField>Status</TableField>
                    <TableValue>Invalid</TableValue>
                </TableRow>
                <TableRow><TableField>Line Type</TableField>

                    <TableValue></TableValue>
                </TableRow>
                <TableRow><TableField>Carrier</TableField>
                    <TableValue></TableValue>
                </TableRow>
                <TableRow><TableField>Location</TableField>
                    <TableValue></TableValue>
                </TableRow>
                {/* <TableRow><TableField>Country Name</TableField>
                    <TableValue>India</TableValue>
                </TableRow> */}
                <TableRow><TableField>Country Timezone</TableField>
                    <TableValue></TableValue>
                </TableRow>
                {/* <TableRow><TableField>Country Code</TableField>
                    <TableValue>IN</TableValue>
                </TableRow> */}
                <TableRow><TableField>International Format</TableField>
                    <TableValue></TableValue>
                </TableRow>
                <TableRow><TableField>Local Format</TableField>
                    <TableValue></TableValue>
                </TableRow>
                <TableRow><TableField>E164 Fornat</TableField>
                    <TableValue></TableValue>
                </TableRow>
                {/* <TableRow><TableField>Can be Internationally Dialed</TableField>
                    <TableValue>-</TableValue>
                </TableRow> */}
                <TableRow><TableField>Validated On</TableField>
                    <TableValue></TableValue>
                </TableRow>
            </Table>
        </TableContainer>}
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'MuiBackdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 550,
};

const ModalContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#FFF'};
  border-radius: 8px;
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 4px 12px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.20)'
  };
  padding: 1rem;
  color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};
  font-family: Gilroy,Arial,sans-serif;
  font-weight: 500;
  text-align: start;
  position: relative;


  & .modal-title {
    font-weight : 600;
    margin: 0;
    font-size: 25px;
    text-align: center;
    line-height: 2rem;
    margin-right: 0.5rem;
  }

  & .modal-icon-valid{
    padding-left: 235px;
    color: green;
    height : 60px;
    width: 60px;
  }
  & .modal-icon-invalid{
    padding-left: 235px;
    color: red;
    height : 60px;
    width: 60px;
  }
  `,
);

const TableField = styled(TableCell)`
color: ${grey[600]};
text-align : end; 
padding : 12px;
width: 50%;
font-weight : 600;
font-size:16px;
font-family: Gilroy,Arial,sans-serif;
`;

const TableValue = styled(TableCell)`
font-weight : 500;
color: ${grey[900]};
width: 50%;
text-align : start;
font-size:16px;
padding : 12px;
font-family: Gilroy,Arial,sans-serif;
`;