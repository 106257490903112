import { Box } from "@mui/material"
import styled from "styled-components"
import { ValidatePage } from "../pages/ValidatePage";

export const HomeBanner = () => {

    return (<Header>
        <div className="banner-container">
            <h1 className="banner-header">30% Dial-to-Connect Ratio is No More a Dream</h1>
            <p className="banner-para">Stop dialing dead-end numbers. Dial numbers that are valid at the right time and increase your call connect rate by 10x</p>
        </div>
        <div className="banner-button">
            <ValidatePage/>
            </div>
    </Header>
    )
}

const Header = styled(Box)`
text-align: center;
height: 750px;
align-items: center;
padding-left: 40px;
padding-right: 40px;
display: flex;
flex-direction: column;
background: #030c21;

& .banner-container{
    max-width: 1000px;
    clear: left;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 30px 30px 30px;
    display: flex;
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left:auto;
    margin-right:auto;
  }

& .banner-header{
    max-width: 20ch;
    color: #fff;
    margin-top: 40px;
    margin-bottom: 17px;
    font-size: 50px;
    font-weight: 700;
    line-height: 59px;
  }

  & .banner-para{
    max-width: 50ch;
    color: #858894;
    margin-top: 1px;
    margin-bottom: 1px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}

& .banner-button{
    padding-bottom:20px;
}
`;


