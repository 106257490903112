class ClientService {
    constructor(){}

    async getPhoneOutputResponse(phoneNumber){
        console.log(phoneNumber);
        return await fetch('https://api.blissy.in/verify?phone_number=' + phoneNumber).then(response => {
            if(response.ok){
                return response.json();
            }
            throw response;
        })
    }
    
}


export default ClientService;
