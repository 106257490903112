import { Box } from "@mui/material"
import styled from "styled-components"

const contentList = [{ numbers: '90%', content: 'of numbers aren’t worth dialing your sales reps time' },
{ numbers: '30%', content: 'dial-to-connect ratio isn’t a dream anymore. It’s achievable when you give correct numbers to your reps' },
{ numbers: '24', content: 'of numbers always go to Voicemail' },
{ numbers: '8x', content: 'enable your reps have more live conversations' }];


export const HomeContent = () => {


    return (
        <><Content>
            <div className="content-title-container">
                <h1 className="content-title">WHY PHONEVERIFY</h1>
            </div>
            <div className="content-column-container">
                {
                    contentList.map(content =>  <ColumnContent>
                        <div><span>{content.numbers}</span>
                        <p>{content.content}</p></div>
                    </ColumnContent>)
                }
            </div>
        </Content>
        </>
    )
}

const Content = styled(Box)`
text-align: center;
align-items: center;
padding-left: 40px;
padding-right: 40px;
background: #f6f6f6;
font-family: Gilroy, Arial, sans-serif;
& .content-title-container{
    max-width: 1000px;
    align-items: center;
    padding: 30px 30px 30px 30px;
    margin-left:auto;
    margin-right:auto;
  }

& .content-title{
    font-size: 35px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #001973;
    position: relative;
    z-index: 2;
    margin-bottom: 20px;
    padding-bottom: 22px;
    position: relative;
  }

  & .content-title::after{
    content: "";
    position: absolute;
    display: block;
    width: 55px;
    height: 3.5px;
    background: #1f074f;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
& .content-column-container{
    padding-bottom: 40px;
display:flex;
}
`;


const ColumnContent = styled(Box)`
flex: 0 0 auto;
width: 25%;
margin-top: 1.5rem;
& div{
position: relative;
letter-spacing: .5px;
}
& span{
    font-size: 50px;
    display: block;
    font-weight: 700;
    margin-bottom: 15px;
    padding-bottom: 15px;
    position: relative;
    color: #5c3ce4;
} 
& span::after {
    content: "";
    position: absolute;
    display: block;
    width: 20px;
    height: 3px;
    background: #1f074f;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
& p{
    font-size: 19px;
    font-weight: 500;
    text-align: center;
    color: #717171;
    width: 300px;
    margin-left: auto;
    margin-right:auto;
}
`;