import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import './PhoneInput.css'

export const PhoneInput = ({phone, handleChange}) => {

 
    return (
        <div className='phone-input'>
        <MuiTelInput style={{fontFamily:'Gilroy'}} sx= {{fontSize:'20px'}}value={phone} defaultCountry="US" onChange={handleChange}/>
        </div>
    )
}
