import './Home.css'
import { HomeBanner } from "../components/HomeBanner"
import { ValidatePage } from "./ValidatePage"
import { HomeContent } from './HomeContent'

export const Home = () => {
    return(<div className='home'>
    <HomeBanner/>
    <HomeContent/>
    </div>)
}