// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-button {
    border: none;
    font-family: Gilroy, Arial, sans-serif; 
    background-color: #f1cd7a;
    border-radius: 24px;
    margin-top: 9px;
    margin-bottom: 9px;
    padding: 17px 46px 16px;
    font-size: 21px;
    font-weight: 700;
    cursor: pointer;
    line-height: inherit;
  }
  
  .primary-button:hover {
    background-color: #d49d20;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/PrimaryButton.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sCAAsC;IACtC,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,oBAAoB;EACtB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".primary-button {\r\n    border: none;\r\n    font-family: Gilroy, Arial, sans-serif; \r\n    background-color: #f1cd7a;\r\n    border-radius: 24px;\r\n    margin-top: 9px;\r\n    margin-bottom: 9px;\r\n    padding: 17px 46px 16px;\r\n    font-size: 21px;\r\n    font-weight: 700;\r\n    cursor: pointer;\r\n    line-height: inherit;\r\n  }\r\n  \r\n  .primary-button:hover {\r\n    background-color: #d49d20;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
