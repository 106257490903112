// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phone-input {
  font-family: 'Gilroy';
  margin: 10px 10px 10px 10px;
}


.phone-input img {
  width: 32px;
  height: 22px;
}

.phone-input input {
  font-size: 20px;
  padding: 17.5px 15px;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
  background: #ffffff;
}

/* .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input{
    font-size: 16px !important;
  } */`, "",{"version":3,"sources":["webpack://./src/components/PhoneInput.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,2BAA2B;AAC7B;;;AAGA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;KAEK","sourcesContent":[".phone-input {\r\n  font-family: 'Gilroy';\r\n  margin: 10px 10px 10px 10px;\r\n}\r\n\r\n\r\n.phone-input img {\r\n  width: 32px;\r\n  height: 22px;\r\n}\r\n\r\n.phone-input input {\r\n  font-size: 20px;\r\n  padding: 17.5px 15px;\r\n}\r\n\r\n.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {\r\n  background: #ffffff;\r\n}\r\n\r\n/* .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input{\r\n    font-size: 16px !important;\r\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
