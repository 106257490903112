import * as React from 'react';
import './NavBar.css';
import { PrimaryButton } from './PrimaryButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { SettingsApplications } from '@mui/icons-material';


const pages = [];
const reponsivePages = ['', "Let's Talk"];

function NavBar() {
  const [isMenuClicked, setIsMenuClicked] = React.useState(false);
  return (
    <>
    <div className='navbar'>
      <div className='nav-container'>
        <a className='nav-brand'>Blissy</a>
        <nav className='nav-menu'>
          {pages.map(page => <a className='nav-link'>{page}</a>)}
          <PrimaryButton style={{ padding: '12px 25px 12px', fontWeight: '600', fontSize: '15px' }} text={"Let's Talk"} />
        </nav>
      </div>
      <div className='menu-icon' onClick={() => setIsMenuClicked(!isMenuClicked)}>
      <MenuIcon sx={{color:'#fff'}}/>
      </div>
      </div>
{isMenuClicked ?<nav className='res-nav-menu'>
          {reponsivePages.map(page => <a className='res-nav-link'>{page}</a>)}
        </nav> : <></>}   
        </>  
  );
}
export default NavBar;
